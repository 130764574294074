
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
:root {
	  font-size:22px;
  color:white;
}
* {
    box-sizing: border-box;
}
body,html {
	width:100%;
	height: 100%;
    margin: 0;
    padding: 0;
	  font-family: "Barlow", sans-serif;
	  font-weight: 100;
	  justify-content: center;
	  align-items: center;
	  background: black;
}
#root{
	width:100%;
	height: 100%;
	height:fit-content;
	padding-bottom:1.5rem;
}
#root div.background-video{
  width: 100% !important; 
  height: 100% !important;  
  overflow: hidden !important; 
  position: absolute;
  top: 0;
  bottom: 0;
}
#root video {
	  /* Make video to at least 100% wide and tall */
  min-width: 100% !important; 
  min-height: 100% !important; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto !important;
  height: auto !important;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%,-50%);
}
canvas{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  transform:translateZ(-1000px);
}
hr{
	width:100%;
}
a{
  color:white;
	padding-left: .2rem;
	padding-right: .2rem;
	font-size:1.2rem;
}
.github-link{
	text-decoration: none;
	background: rgb(28,28,28);
	background: linear-gradient(0deg, rgba(28,28,28,1) 0%, rgba(71,71,71,1) 100%);
	padding:.4rem;
	border-radius: .3rem;
	outline:1px solid rgba(0,0,0,0);
	transition: all .5s ease;
	min-width: fit-content;
	flex-direction: row;
	display: flex;
	font-size:24px;
}
.github-link i{
	margin:auto;
	font-size:32px;
	padding-right:8px;
}
.github-link:hover{
	background: rgb(10,10,10);
	background: linear-gradient(0deg, rgba(15,15,15,1) 0%, rgba(50,50,50,1) 100%);
	padding:.4rem;
	border-radius: .3rem;
	outline:1px solid #fffffaa1;
	box-shadow:0 3px 12px rgba(0,0,0,.35);
	text-shadow:0 0 5px white;
}
button{
	  font-family: "Barlow", sans-serif;
	  font-weight: 100;
		font-size:32px;
}
h5{
	font-size: 1rem;
}
h1,h2,h3,h4,h5,h6{
	text-align: left;
	padding: 0px;
	margin: 0px;
}
.audio{
	position: fixed;
	bottom:0;
	right:0;
	z-index: 10;
}
.audio button{

	z-index: 10;
	left: auto;
	right: auto;
}

@media screen and (min-width: 992px) {
	.audio{
    left: 0;
    right: 0;
    text-align: center;
	}
}
/****** grid */
.row *{

  	z-index: 1;
}
.row{
	position:relative;
	display: flex;
	flex-direction: column;
	align-items: center;
  	z-index: 1;
}
.col{
  	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;	
}
@media screen and (min-width: 992px) {
	.row{
		display: flex;
		flex-flow:row wrap;
	}
}

/********* Perspective */
#perspective-container {
	display: flex;
	flex-direction: column;
  align-items: center;
}

.perspective-far{
	transform: perspective(556px) translateZ(-250px);
}
.perspective {
  height: fit-content;
  width: fit-content;
}

/******* Text */
.text-center{ 
	text-align: center;
}
.title , .title .line, .title a {
	color:white; 
	display: block;
	font-weight: 300;
	font-size:3rem;
	line-height: 3.2rem;
	border: none;
	text-decoration: underline;
}

@media screen and (min-width: 992px) {
	.title , .title .line, .title a {
		font-size:5rem;
		line-height: 5rem;
	}

}

.glitch {
	color:white;
	border: none;
	background-position: 0 0, 5px 5px;
	--var-col :rgba(255,245,245,.25);
	background: 
	repeating-linear-gradient( rgba(0,0,0,0.5), rgba(0,0,0,0.5) 1px,var(--var-col)  1px, var(--var-col) 2px),
	repeating-linear-gradient(0.25turn, rgba(0,0,0,0.5), rgba(0,0,0,0.5) 1px, var(--var-col) 1px, var(--var-col) 2px);

}

/*********** Projects */
#projects{
	padding: 1rem;
	padding-top: .5rem;
	max-width:100vw;
}
#projects > p{
	max-width:850px;
}

.project h1,.project h2,.project h3,.project h4,.project h5,.project h6{
	font-weight:300;
	margin:unset;
}
.project { 
	border-radius: .3rem;
	margin-bottom: 2rem;
	background:#41587a;
/*	background: conic-gradient(from 30deg, #41587a, #0a244c 50%, #41587a);*/
/*	background: radial-gradient(circle,
	 hsl(214.8deg,  67.57%,  14.51%, 100%) 0%,
	 hsl(216.36deg, 76.74%,  16.86%, 100%) 30%,
	 hsl(215.79deg, 30.48%,  36.67%, 100%) 100%);*/
/*	border: 3px solid rgba(255,255,255,0.8);*/
/*	border-radius: 1rem;*/
	background: linear-gradient(4deg, hsl(215.79deg, 30.48%, 36.67%) 0%, hsl(216.36deg, 76.74%, 16.86%) 21%, hsl(215.79deg, 30.48%, 36.67%) 100%);
	
	display: flex;
	padding:.5rem;
  width:350px;
  min-height: 350px;
}

@for $i from 3 through 8 {

  .project:nth-child(#{$i}) {

  	// sass-lint:disable-next-line 
  	background: linear-gradient(4deg, hsl(215.79deg+$i/8*360deg, .5*30.48%, .9*36.67%) 0%,hsl(216.36deg+$i/8*360deg, .5*76.74%, .9*16.86%) 21%,hsl(215.79deg+$i/8*360deg, .5*30.48%, .9*36.67%) 100%); 

  	
  }
}
/*.project > .col {
	flex:0;
}*/
.project .logo{
	padding:5px;
	background:white;
	border-radius:15px;
	height:64px;
	width:64px;
	margin:3px;

}
.project .github-link {
	align-self:center;
}
.project_main {
	flex:1;
	height:100%;
	width:100%;
} 
.project  p{
  margin:0px;
	text-align: center;
}
.project_main > :not(.intro) p{
  height:0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal ;
  max-width: 28ch;
}
.project .images ul{

  padding-left:0;
}
.project ul{
  overflow: hidden;
  height:0;
  margin:0px;

}
.project .images img{
	max-width:100%;
}
.project label:hover{
	cursor: pointer;
}
.project label{
	text-decoration: underline;
	display: inline;
	margin:0;
  margin-bottom:.6rem;
}
.project input[type="checkbox"] {
	display:none;
}
.project label:before{
	content:'click me';
}
.project input[type="checkbox"]:checked {
		~ p {
			height: fit-content;
			width:unset;
			flex:1;
		}
		~ ul {
			height:fit-content;
			flex:1;
		}
		~ label:before {
			content:'close ';
		}
}

@media screen and (min-width: 992px) {
	.project h1,.project h2,.project h3,.project h4,.project h5,.project h6{
		align-self: flex-start;
	}
	.project{
		padding:1rem;
	}
	.project > * {
		padding:1rem;
		align-items: unset;
	}
	.project  .images > label{
		align-self: flex-start;
	}
	.project  :not(.images) > label{
		display: none;
	}
	.project .project_main{
		flex:1;
	}
	.project > .col {
		align-self: flex-start;
	}
	.project > :not(.images) > ul{
		flex:1;
		margin-top:.25rem;
		margin-bottom:.5rem;
	}
	.project_main > :not(.intro) p{
		text-align: left;
		flex:1;
		margin-top:.25rem;
		margin-bottom:.25rem;
		align-self: flex-start;
  	max-width: unset;
	}
	.project .logo {
		padding:1rem;
		align-self: flex-start;
		height:200px;
		width:200px;
		border-radius:64px;
	}
.project {
	width:900px;
}
}

@media screen and (min-width: 1200px) {
.project {
	width:1100px;
}
}

/*********** Cards */


/***/
.card .media{

	display: none;
	margin: auto;
	margin-bottom: .5rem;
}
@media screen and (min-width: 992px) {
	.card .media{
		display: block;
	}
}

.card .media > div img {
		height: 256px;
		width: 256px;
}
.card a{
	font-size:2rem;
 	display: flex;
 	flex-direction: column;
  /*border: 1px dotted white;*/
  justify-content: center;
  text-align: center;
  padding: .1rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

@media screen and (min-width: 992px) {
	.card a{/*
	  margin-top: 1rem;
	  margin-bottom: 1rem;*/
	  margin:1rem;
	}
}
/********************* Effects */
.fly-in{
	will-change: left;
	animation: fly-in 1.0s  ;
}
 .navlink a{
	font-size:2rem;
}
.card a:hover, .navlink a:hover, button:hover {
    animation: shake 525ms infinite;
    font-weight: bold;
    cursor: pointer;
}

@keyframes shake {
  0% {
        -webkit-filter: hue-rotate(0deg);
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
    transform: translateY(2px);
  }
  65% {
    transform: translateX(3px);
    transform: translateY(-1px);
  }
  70% {
    transform: translateX(-2px);
  }
  85% {
    transform: translateX(2px);
    transform: translateY(1px);
  }
  100% {
    transform: translateX(0);
    transform: translateY(-1px);
        -webkit-filter: hue-rotate(-360deg);
  }
}

@keyframes fly-in {
0%{
		opacity: 0;
		transform:perspective(1000px)  translateZ(-1000px) ;
	}
30%{
		opacity: 0;
		transform:perspective(1000px) translateZ(-1000px) ;
	}
	100%{
		opacity: 1;
		transform: perspective(1000px) translateZ(0px) ;
	}
}

@media screen and (min-width: 992px) {

@keyframes fly-in {
	0%{
		opacity: 0;
		transform:perspective(1000px) translateZ(-10000px) ;
	}
	30%{
		opacity: 0;
		transform:perspective(1000px)  translateZ(-10000px) ;
	}
	100%{
		opacity: 1;
		transform: perspective(1000px) translateZ(0px) ;
	}

}
}
/******* CUBE */
.radio-group {
	padding:.25rem;
}
.radio-group input{
 display: none;
}
.radio-group label:hover{
	cursor: pointer;
	font-weight: bold;
	animation: shake 525ms infinite;
}
.radio-group input[type="radio"]:checked+label{

	text-decoration: underline;
}
.scene {
  width: 200px;
  height: 200px;
  margin: 80px;
  transform-style: preserve-3d;
  transform:perspective(300px);
}

.cube {
  width: 200px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100px);
  transition: transform 1s;
}


.cube.show-front  { transform: translateZ(-100px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-100px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-100px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-100px) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(-100px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-100px) rotateX(  90deg); }
@font-face {
  font-family: fixedsys;
  src: url('../public/resources/font/fixedsys.ttf');
}
.cube__face {
	font-family: fixedsys;
  position: absolute;
  width: 200px;
  height: 200px;
  border: 2px solid white;
  color: white;
  padding: .25rem;
  font-size: 1.2rem;
}
.cube__face img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: .5;
}
.cube__face h3, .cube__face p {
	padding: 0;
	margin: 0;
	width: 100%;
}
.cube__face {
	transition: filter 1s;
	filter: saturate(0%);
}
.cube.show-front .cube__face--front,
.cube.show-right .cube__face--right,
.cube.show-back .cube__face--back,
.cube.show-left .cube__face--left,
.cube.show-top .cube__face--top,
.cube.show-bottom .cube__face--bottom,
 {filter: saturate(100%);}

.cube__face--front  { background: hsla(  0, 100%, 50%, 0.7); }
.cube__face--right  { background: hsla( 60, 100%, 50%, 0.7); }
.cube__face--back   { background: hsla(120, 100%, 50%, 0.7); }
.cube__face--left   { background: hsla(180, 100%, 50%, 0.7); }
.cube__face--top    { background: hsla(240, 100%, 50%, 0.7); }
.cube__face--bottom { background: hsla(300, 100%, 50%, 0.7); }

.cube__face--front  { transform: rotateY(  0deg) translateZ(100px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(100px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(100px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(100px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(100px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(100px); }

label { margin-right: 10px; }

@media screen and (min-width: 592px) {

.scene , .cube , 
.cube__face {
  width: 300px;
  height: 300px;
}
.cube__face--front  { transform: rotateY(  0deg) translateZ(150px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(150px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(150px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(150px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(150px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(150px); }

	}
/******  Shoe */
.mt-5{
margin-top:  5rem;
}
.mt-1{
margin-top: 1rem;
}
.pt-3{
	padding-top: 3rem;
}